<template>
  <div>
    <div v-if="!isLoading">
      <TopMenuSubheadline :title="title" />
      <div class="app-content-box bg-gray profile-reviews">
        <div class="container reviews-container" v-if="reviews.length">
          <div class="review-box" v-for="(review, key) in reviews" :key="key">
            <ReviewElement :props="review" />
          </div>
        </div>
      <div  v-else class="container notification-list-wrapper">
        <div class="container empty-messages-info text-center">
          <b>Przykro nam. <br> Nie masz jeszcze recenzji.</b>
        </div>
        <div class="container pb-4" >
          <div class="quote messages-quote">
            Radość pisania.<br> Możność utrwalania.<br> Zemsta ręki śmiertelnej
          </div>
        </div>
      </div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
import ReviewElement from '../components/ReviewElement'
export default {
  name: 'Review',
  components: {
    TopMenuSubheadline,
    Loader,
    ReviewElement
  },
  data () {
    return {
      title: 'moje recenzje',
      isLoading: true,
      reviews: []
    }
  },
  async created () {
    await this.$https('/profile/review', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.reviews = data.response
        this.isLoading = false
      })
      .catch(error => {
        console.log('', error)
      })
  }
}
</script>
<style lang="scss" scoped>
.app-content-box {
  min-height: 100vh;
}
.reviews-container {
  margin-top: 65px;
  margin-bottom: 40px;
}
  .notification-list-wrapper {
    margin-top: 60px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    &-empty {
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
